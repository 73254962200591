import { ADD_RESULT } from '../actions/types';
import { ActionType } from 'typesafe-actions';
import * as actions from '../actions/results.action';

type ResultsState = Array<string>;
type ResultsAction = ActionType<typeof actions>;

const resultsReducer = (results: ResultsState = [], action: ResultsAction) => {
  switch (action.type) {
    case ADD_RESULT:
      return [...results, action.payload];
    default:
      return results;
  }
};

export default resultsReducer;
