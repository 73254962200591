import React from 'react';
import { connect } from 'react-redux';
import { InputGroup, FormControl, Button } from 'react-bootstrap';

import { modifyOption, removeOption } from '../../actions/options.action';

interface OptionItemProps {
  index: number,
  value: string,
  modifyOption: (params: { index: number, value: string }) => void,
  removeOption: (id: number) => void,
}

interface OptionItemState {}

class OptionItem extends React.Component<OptionItemProps, OptionItemState> {
  // TODO: Check if correct event type.
  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.modifyOption({
      index: this.props.index,
      value: event.target.value,
    });
  };

  // TODO: Check if correct event type.
  onRemoveClick = () => {
    this.props.removeOption(this.props.index);
  };

  render() {
    let { value } = this.props;

    return (
      <InputGroup className="mb-3 option-item">
        <FormControl value={value} placeholder="Put another option here." onChange={this.onInputChange} />
        <InputGroup.Append>
          <Button variant="outline-danger" onClick={this.onRemoveClick}>
            Remove
          </Button>
        </InputGroup.Append>
      </InputGroup>
    );
  }
}

export default connect(undefined, { modifyOption, removeOption })(OptionItem);
