export const SET_PROMPT = 'prompt/SET';

export const ADD_OPTION = 'option/ADD';
export const REMOVE_OPTION = 'option/REMOVE';
export const MODIFY_OPTION = 'option/MODIFY';

export const SET_OPTIONS = 'options/SET';

export const ADD_RESULT = 'result/ADD';

export const LOGIN = 'user/LOGIN';
export const LOGOUT = 'user/LOGOUT';
