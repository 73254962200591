import { Preset } from '../models/preset';
import { Store } from 'redux';
import { addOption, setOptions } from '../actions/options.action';
import { setPrompt } from '../actions/prompt.action';

export const applyPreset = (store: Store) => (preset: Preset, addEmptyOption: boolean = false): void => {
  const { prompt, options } = preset;

  store.dispatch(setPrompt(prompt));
  store.dispatch(setOptions(options));

  if (addEmptyOption) {
    store.dispatch(addOption());
  }
};
