import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import firebase from 'firebase/app';
import 'firebase/auth';
import { random } from 'lodash';

import * as serviceWorker from './serviceWorker';
import App from './components/app/App';
import reducers from './reducers';
import { login, logout } from './actions';
import TopBar from './components/TopBar';
import Profile from './components/profile/Profile';
import { DEFAULT_PRESETS } from './resources/preset.default';
import { applyPreset } from './helpers/preset.helpers';

// TODO: Put config elsewhere.
firebase.initializeApp({
  apiKey: 'AIzaSyDQCku4-REWVQQ5VDgQC2z560HBrr6X2mA',
  authDomain: 'decisionmaker.xyz',
  projectId: 'decision-maker-b6b46',
});

const store = createStore(reducers, composeWithDevTools(applyMiddleware()));

firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    store.dispatch(login(user));
  } else {
    store.dispatch(logout());
  }
});

// Randomly select a preset and populate UI.
const presets = DEFAULT_PRESETS;
const preset = presets[random(0, presets.length -  1)];
applyPreset(store)(preset, true);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Route path="/" exact component={App} />
      <Route path="/profile" exact component={Profile} />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
