import { ActionType } from 'typesafe-actions';
import * as actions from '../actions/prompt.action';
import { SET_PROMPT } from '../actions/types';

type PromptState = string;
type PromptAction = ActionType<typeof actions>;

const promptReducer = (prompt: PromptState = '', action: PromptAction) => {
  switch (action.type) {
    case SET_PROMPT:
      return action.payload;
    default:
      return prompt;
  }
};

export default promptReducer;
