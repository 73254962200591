import React from 'react';
import { connect } from 'react-redux';
import { Button, Container, Card, Form } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/auth';

import './Profile.css';

// TODO: Change this file to ts.
class Profile extends React.Component {
  state = {
    text: null,
    statusMessage: null,
  };

  text = `Let's hook you up.`;

  signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    await this.signInWithPopup(provider);
  };

  signInWithFacebook = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    await this.signInWithPopup(provider);
  };

  signInWithPopup = async (provider) => {
    const auth = firebase.auth();

    try {
      await auth.signInWithPopup(provider);
      await this.redirectToPreviousPage();
    } catch (error) {
      this.setState({
        statusMessage: error.message,
      });
      if (error.code === 'auth/account-exists-with-different-credential') {
        const pendingCred = error.credential;
        const email = error.email;
        const methods = await auth.fetchSignInMethodsForEmail(email);
        const provider = this.getProviderForProviderId(methods[0]);
        const result = await auth.signInWithPopup(provider);
        const cred = await result.user.linkWithCredential(pendingCred);
        await this.redirectToPreviousPage();
      }
    }
  };

  getProviderForProviderId = (providerId) => {
    switch (providerId) {
      case 'google.com':
        return new firebase.auth.GoogleAuthProvider();
      case 'facebook.com':
        return new firebase.auth.FacebookAuthProvider();
      default:
        return undefined;
    }
  };

  signOut = async () => {
    try {
      const result = await firebase.auth().signOut();
      await this.redirectToPreviousPage();
    } catch (error) {
      this.setState({ statusMessage: error.message });
      console.error(error);
    }
  };

  redirectToPreviousPage = async () => {
    const { location, history } = this.props;
    const { state } = location;

    if (state && state.from) {
      history.push(state.from);
    } else {
      history.push('/');
    }
  };

  renderSignInBanner() {
    return <h1 className="title profile-banner">Let's hook you up.</h1>;
  }

  renderUserProfile(user) {
    return (
      <div className="profile">
        <div className="profile-avatar">
          <img src={user.photoURL} />
        </div>
        <h2 className="title">{user.displayName}</h2>
      </div>
    );
  }

  renderLoginButtons() {
    return (
      <>
        <Form.Group>
          <Button className="profile-button" variant="secondary" onClick={this.signInWithGoogle}>
            Sign in with Google
          </Button>
        </Form.Group>
        <Form.Group>
          <Button className="profile-button" variant="secondary" onClick={this.signInWithFacebook}>
            Sign in with Facebook
          </Button>
        </Form.Group>
      </>
    );
  }

  renderLogoutButtons() {
    return (
      <Form.Group>
        <Button className="profile-button" variant="danger" onClick={this.signOut}>
          Sign out
        </Button>
      </Form.Group>
    );
  }

  renderStatusMessage() {
    const { statusMessage } = this.state;
    if (statusMessage) {
      return <div className="profile-status">{statusMessage}</div>;
    } else {
      return undefined;
    }
  }

  renderMobileContainer(avatar, content) {
    return (
      <Container className="profile-card-container">
        <Form>
          {avatar}
          {content}
        </Form>
      </Container>
    );
  }

  renderDesktopContainer(avatar, content) {
    return (
      <Container className="profile-card-container">
        <Card className="profile-card">
          <Card.Body>
            <Form>
              {avatar}
              {content}
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  render() {
    const { user } = this.props;

    let topContent;
    let bottomContent;
    if (!user) {
      topContent = this.renderSignInBanner();
      bottomContent = this.renderStatusMessage() || this.renderLoginButtons();
    } else {
      topContent = this.renderUserProfile(user);
      bottomContent = this.renderStatusMessage() || this.renderLogoutButtons();
    }

    if (window.innerWidth <= 450) {
      return this.renderMobileContainer(topContent, bottomContent);
    } else {
      return this.renderDesktopContainer(topContent, bottomContent);
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, {})(Profile);
