import { ADD_OPTION, MODIFY_OPTION, REMOVE_OPTION, SET_OPTIONS } from '../actions/types';
import { ActionType } from 'typesafe-actions';
import * as actions from '../actions/options.action';

type OptionsState = Array<string>;
type OptionsAction = ActionType<typeof actions>;

const optionsReducer = (options: OptionsState = [], action: OptionsAction) => {
  switch (action.type) {
    case ADD_OPTION:
      return [...options, action.payload];
    case REMOVE_OPTION:
      return options.filter((v, i) => i !== action.payload);
    case MODIFY_OPTION:
      return options.map((v, i) => (i === action.payload.index ? action.payload.value : v));
    case SET_OPTIONS:
      return [...action.payload];
    default:
      return options;
  }
};

export default optionsReducer;
