import { LOGIN, LOGOUT } from '../actions/types';

// TODO: Change this file to ts.
export default (user = null, action) => {
  switch (action.type) {
    case LOGIN:
      return action.payload;
    case LOGOUT:
      return null;
    default:
      return user;
  }
};
