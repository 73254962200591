import './AnimatedButton.css';
import React from 'react';

interface AnimatedButtonProps {
  text: string;
  color: string;
  onClick: () => void;
}

interface AnimatedButtonState {}

class AnimatedButton extends React.Component<AnimatedButtonProps, AnimatedButtonState> {

  state = {
    active: false
  };

  onClick = () => {
    this.setState({ active: false });
    setTimeout(() => this.setState({ active: true }), 50);

    if (this.props.onClick) this.props.onClick();
  };

  render() {
    const { text, color } = this.props;

    let className = 'unselectable animated-button';
    className += this.state.active ? ' active' : '';


    const animationSpans = [];
    for (let i = 0; i < 8; i++) {
      animationSpans.push(<span key={i} style={{ background: color }} />);
    }

    return (
      <button onClick={this.onClick} className={className} style={{ color }}>
        <div className={'shape'}>
          {text}
          {animationSpans}
        </div>
      </button>
    );
  }
}

export default AnimatedButton;
