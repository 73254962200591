import './OptionsList.css';
import React from 'react';
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import OptionItem from './OptionItem';
import { RootState } from '../../store/types';
import { addOption } from '../../actions/options.action';

interface OptionsListProps {
  options: Array<string>;
  addOption: (value: string) => void;
  onSubmit: () => void;
}

interface OptionsListState {}

class OptionsList extends React.Component<OptionsListProps, OptionsListState> {
  startingValue = 'TAP TO DECIDE';

  onAddOptionClick = () => {
    this.props.addOption('');
  };

  renderList() {
    let { options } = this.props;
    return options.map((option, index) => {
      return <OptionItem key={index} index={index} value={option} />;
    });
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props.onSubmit();
  };

  render() {
    return (
      <Form className="options-list">
        <Container>{this.renderList()}</Container>
        <div className="text-center add-option-container">
          <Button variant="outline-secondary" onClick={this.onAddOptionClick}>
            Add Option
          </Button>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    options: state.options,
  };
};

export default connect(mapStateToProps, { addOption })(OptionsList);