import './App.css';
import React from 'react';
import { connect } from 'react-redux';
import { random } from 'lodash';

import OptionsList from './OptionsList';
import AnimatedButton from './AnimatedButton';
import { RootState } from '../../store/types';
import { addResult } from '../../actions/results.action';

interface AppProps {
  prompt: string;
  options: Array<string>;
  results: Array<string>;
  addResult: (value: string) => void;
}

interface AppState {}

class App extends React.Component<AppProps, AppState> {
  // TODO: Put this in a constants file.
  private colors = ['#20c997', '#e83e8c', '#20c997', '#ffc107', '#17a2b8', '#fd7e14'];
  private offset = random(0, this.colors.length - 1);

  private drawResult = () => {
    let { options, addResult } = this.props;

    options = options.filter((option) => option);

    if (options.length === 0) {
      return;
    }

    let selectedIndex = Math.floor(Math.random() * options.length);
    let selectedOption = options[selectedIndex];
    addResult(selectedOption);
  };

  private renderTitle() {
    const { prompt } = this.props;
    return <h1 className="title app-title">{prompt}</h1>;
  }

  // TODO: Can I get rid of onSubmit here?
  private renderOptionsList() {
    return <OptionsList onSubmit={this.drawResult} />;
  }

  private renderButton() {
    const { results } = this.props;

    if (results.length === 0) {
      const text = 'TAP TO DECIDE';
      const color = 'black';

      return this.animatedButton(text, color, this.drawResult);
    } else {
      const l = results.length;

      const text = results[l - 1];
      const color = this.colors[(this.offset + l) % this.colors.length]

      return this.animatedButton(text, color, this.drawResult);
    }
  }

  private animatedButton(text: string, color: string, onClick: () => void) {
    return (
        <div className="animated-button-container">
          <AnimatedButton text={text} color={color} onClick={onClick} />
        </div>
    );
  }

  render() {
    return (
      <>
        {this.renderTitle()}
        {this.renderOptionsList()}
        {this.renderButton()}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    prompt: state.prompt,
    options: state.options,
    results: state.results
  };
};

export default connect(mapStateToProps, { addResult })(App);