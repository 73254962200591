import { combineReducers } from 'redux';
import promptReducer from './prompt.reducer';
import resultsReducer from './results.reducer';
import optionsReducer from './options.reducer';
import userReducer from './user.reducer';

export default combineReducers({
  prompt: promptReducer,
  options: optionsReducer,
  results: resultsReducer,
  user: userReducer,
});
